import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Input } from 'reactstrap';

function PenknifeFilter({select}) {

   const [subcats, setSubcats] = useState([]);
   const [error, setError] = useState("");

   useEffect(()=> {
      fetchSubcats();
   }, []);

   async function fetchSubcats() {
      try {
          const url = `${process.env.REACT_APP_BACKEND_URL}/termekalkategoriak`;
          const {data} = await axios.get(url);
          setSubcats(data);
          //console.log(data);
      } catch (err) {
          //console.log(err);
          setError("Nem sikerült a bicska alkategóriák letöltése.");
      }
  }

  function handleChange(e) {
   if (e.target.value !== "all") {
      let subcatId = Number(e.target.value);
      select(subcatId);
   } else {
      select("all");
   }
   //console.log(e.target.value);
  }

  function renderSubcats() {
   if (subcats.length > 0) {
      let options = [];
      options.push(<option value="all">Összes</option>);
      let genericOptions = subcats.map((subcat, ind) => 
         <option key={ind} value={subcat.idknives_subcategories}>
            {subcat.subcategory_name}
         </option>
      );
      options.push(genericOptions);
      let subcatSelect = 
         <div className="subcat-select-wrapper">
            <label htmlFor="subcat-filter">Bicska alkategóriák</label>
            <Input 
               type='select'
               name="subcat-filter" 
               id="subcat-filter"
               onChange={e=>handleChange(e)}
               style={{ backgroundColor: "#cedc00" }}
            >
               {options}
            </Input>
         </div>;
      return subcatSelect;
   }
  }

  return (
    <div className='subcat-filter-wrapper'>
      {error !== "" ?
         (error)
         :
         (renderSubcats())
      }
    </div>
  );
}

export default PenknifeFilter;
