import React, {useEffect, useState} from 'react';
import { Spinner } from 'reactstrap';

function OrderConfirmation() {

   const [response, setResponse] = useState({});

   useEffect(() => {
      const serverResponse = sessionStorage.getItem("serverResponse");
      if (serverResponse && serverResponse.length > 0) {
         const res = JSON.parse(serverResponse)
         setResponse(res);
      }
      sessionStorage.clear();
   }, []);

  return (
   <>
   <h1>
      {Object.keys(response).length > 0 && response.status === "error" ? 
         "Sikertelen rendelés" : "Köszönjük rendelését!"}
   </h1>
      <div className='flex-container'>
      {Object.keys(response).length > 0 ? 
         (<div style={{ color: "#cedc00" }}>
            {response.msg}
         </div>
         )
         :
         (<Spinner>Loading...</Spinner>)
      }
      </div>
    </>
  );
}

export default OrderConfirmation;
