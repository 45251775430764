import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { imgHost } from '../utils/utils';
import 'lightbox2/dist/css/lightbox.min.css';
import 'lightbox2/dist/js/lightbox-plus-jquery';
import { Spinner } from 'reactstrap';

function Galeria() {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchGalleryImages();
  }, []);

  async function fetchGalleryImages() {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/gallery`;
      const {data} = await axios.get(url);
         if (Array.isArray(data) && data.length > 0) {
            setImages(data);
         } else {
            setError("Úgy tűnik a képgaléria jelenleg üres...");
         }
    } catch(err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  function renderPhotos() {
    const photos = images.map((item, ind) => 
      <div key={ind}>
        <a 
          href={imgHost + item.image_path} 
          data-lightbox="roadtrip">
            <img 
              src={imgHost + item.thumbnail_path} 
              alt={item.img_description} 
            />
        </a>
        <div className='img-desc'>
            {item.img_description}
        </div>
      </div>
  );
    return photos; 
  }

  return (
    <div>
      <h1>Galéria</h1>
        
      <div className='gallery-wrapper'>
        {loading ? 
          (<Spinner>Loading...</Spinner>) 
          : 
          (images.length > 0 && renderPhotos())
        }
        
      </div>
    </div>
  )
}

export default Galeria;
