export function priceFormatter(value) {
   const formatter = new Intl.NumberFormat('hu-HU', {
       style: 'currency',
       currency: 'HUF',
       maximumFractionDigits: 0
   });
   return formatter.format(value);
}

export function knifeTypeFormatter(type) {
   switch (type) {
       case "penknife":
           return "bicska";
       case "dagger":
           return "tőr";
       case "kitchen_knife":
           return "konyhakés";
       case "wright_penknife":
           return "míves bicska";
       default:
           return type;
   }
}

export function customerDataFormatter(field) {
   switch (field) {
       case "cname":
           return "Vásárló neve";
       case "email":
           return "Email";
       case "phone":
           return "Telefon";
       case "post_code":
           return "Irányítószám";
       case "settlement":
           return "Település";
       case "street":
           return "Utca";
       case "details":
           return "Házszám";
       case "bill_post_code":
           return "Ir.szám (számlázási)";
       case "bill_settlement":
           return "Település (számlázási)";
       case "bill_street":
           return "Utca (számlázási)";
       case "bill_details":
           return "Házszám (számlázási)";
       case "bill_name":
           return "Számlázási név"; 
       default:
           return field;
   }
}

export function deliveryStatusFormatter(status) {
   switch (status) {
       case "PENDING":
           return "Függőben";
       case "CANCELLED":
           return "Visszavont";
       case "COMPLETED":
           return "Teljesült";
       default:
           return status;
   }
}

export function knifeDataFormatter(field) {
   switch (field) {
       case "available":
           return "Elérhető";
       case "blade_length":
           return "Penge hossza";
       case "blade_material": 
           return "Penge anyaga";
       case "blade_width":
           return "Penge vastagsága";
       case "buyable":
           return "Megvásárolható";
       case "description":
           return "Leírás";
       case "full_length":
           return "Teljes hossz";
       case "handle_material":
           return "Markolat anyaga";
       case "hardness":
           return "Keménység";
       case "hide_if_sold_out":
           return "Elrejtés, ha nincs készleten";
       case "name":
           return "Megnevezés";
       case "price":
           return "Ár";
       case "type":
           return "Típus";
       case "type_subcategory":
           return "Típus alkategória";
       case "subcategory_name":
           return "Alkategória";
       case "stock":
           return "Készlet";
       default:
           return field;
   }
}

export const knifeTypes = [
   {
       "name": "penknife",
       "nev": "bicska"
   },
   {
       "name": "dagger",
       "nev": "tőr"
   },
   {
       "name": "kitchen_knife",
       "nev": "konyhakés"
   },
   {
       "name": "wright_penknife",
       "nev": "míves bicska"
   }
];

export function saleFieldFormatter(field) {
   switch (field) {
       case "deadline":
           return "Akció vége";
       case "sale_percentage":
           return "Akció mértéke (%)";
       case "until_in_stock": 
           return "Készlet erejéig";
       case 0:
           return "Nem";
       case 1:
           return "Igen";
       default:
           return field;
   }
}

export const product_categories = [
    {
      category_name: "Bicskák",
      category_desc: "Tekintse meg bicskáink széles választékát, melyben 20 bicskatípus termékei közül válogathat",
      category_img: "../../assets/bicska.jpg",
      category_link: "/bicskak/"
    },
    {
      category_name: "Míves bicskák",
      category_desc: "Különleges bicskáink",
      category_img: "../../assets/mives-bicska.jpg",
      category_link: "/mives-bicskak/"
    },
    {
      category_name: "Konyhakések",
      category_desc: "Ha a konyhájában is kiváló minőségű késsel szeretne dolgozni",
      category_img: "../../assets/konyhakes.jpg",
      category_link: "/konyhakesek/"
    },
    {
      category_name: "Tőrők",
      category_desc: "Elsősorban horgászok és vadászok figyelmébe ajánljuk egyedi készítésű tőreinket",
      category_img: "../../assets/tor.jpg",
      category_link: "/torok/"
    }
  ];

  export const imgHost = "https://www.boznanszkykes.hu/";

  export function createGallery(imageDataArray) {
    const newImageArray = imageDataArray.map(item => {
       return({ 
          largeURL: imgHost + item.image_path, 
          thumbnailURL: imgHost + item.thumbnail_path 
          /* width: 1500, 
          height: 844 */
       });
    });
    return newImageArray;
 }

 export function getShortName(name) {
    if (name.length < 25) {
       return name;
    }
    return `${name.substr(0, 22)}...`;
 }

 export function isItInStock(stock) {
    if (stock > 0) {
       return `Készleten ${stock} db`;
    }
    return "Rendelhető";
 }

 export function isItOnSale(salePerc, price) {
    if (salePerc > 0) {
       let saleAmount = salePerc / 100 * price;
       let newPrice = price - saleAmount;
       return `Akciós ár: ${priceFormatter(newPrice)}`;
    } return priceFormatter(price);
 }

 export function isItOnSale_v2(salePerc, price, key) {
    if (Number(salePerc) > 0) {
       let saleAmount = Number(salePerc) / 100 * Number(price);
       let newPriceValue = Number(price) - Number(saleAmount);
       let oldPrice = 
        <div className="old-price" key={`op-${key}`}>
            {priceFormatter(price)}
        </div>;
       let newPrice = 
        <div className="new-price" key={`np-${key}`}>
            {priceFormatter(newPriceValue)}
        </div>;
       let priceContainer = [];
       priceContainer.push(oldPrice, newPrice);
       return <div className="price-container" key={key}>{priceContainer}</div>;
       
       
    } return priceFormatter(price);
 }

 export function calcBasketSumPrice(basket, formatted = true) {
    let totalPrice = basket.reduce((sum, curr) => sum + (curr.productDiscountedPrice * curr.quantity), 0);
    if (formatted) {
       return priceFormatter(totalPrice);
    } else {
       return totalPrice;
    }
 }

 export function checkBasketItems() {
    //kosárban lévő tételek számának meghatározása
    const basket2 = sessionStorage.getItem("basket");
    if (basket2 && basket2.length > 0) {
       let basketArray2 = JSON.parse(basket2);
       return basketArray2.length;
    }
    //console.log(basket2);
    return 0;
 }

 export function scrollTop() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
     });
 }

 export function hideMobMenu() {
    const navContainer = document.querySelector(".nav-container");
    const primaryNav = document.querySelector(".primary-navigation");

    //const visibility = navContainer.getAttribute("data-visible");
    navContainer.setAttribute("data-visible", false);
    primaryNav.setAttribute("aria-expanded", false);
}

//a függvény a bemenő paraméterek alapján ellenőrzi, hogy az adott kosárművelet (tételszám növelése) engedélyezett-e. Ugyanis amikor egy termék egyaránt akciós ÉS készleten is van, akkor maximálisan csak a készleten lévő mennyiség kerülhet belőle a kosárba 
export function isItAllowedToInc(salePercentage, stock, newQuantity) {
    // ha nem akciós, akkor mindig igazzal tér vissza
    if (Number(salePercentage) < 1) return true; 

    //ha ide érünk, akkor biztos, hogy akciós
    //ha akciós, de a készlet 0 akkor szintén igazzal térünk vissza
    if (Number(stock) === 0) return true; 

    //ha az új mennyiség nagyobb, mint a készleten lévő mennyiség, akkor hamissal kell visszatérni.
    if (Number(newQuantity) > Number(stock)) return false;
    //egyébként 
    return true;
 }