import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';


function Kereso() {

   const navigate = useNavigate();
   const [keyword, setKeyword] = useState("");
   const [error, setError] = useState("");

   function handleSubmit(e) {
      e.preventDefault();
      if (keyword.length > 2) {
         navigate(`/kereses?keyword=${encodeURIComponent(keyword)}`);
      } else {
         setError("Kérlek adj meg legalább 3 karaktert a kereséshez!");
      }
   }

  return (
    <div className='finder-wrapper'> 
      <form onSubmit={e=>handleSubmit(e)}>
         <div className="form-group">
            <input 
               type="text" 
               name='keyword'
               placeholder='Termékkereső'
               onChange={e=>setKeyword(e.target.value)}
               className='main-search'
               required
            />
            <button className='search-btn'>Keresés</button>
         </div>
         {error !== "" && <div className="nothing-find">{error}</div>}
      </form>
    </div>
  );
}

export default Kereso;
