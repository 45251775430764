import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
const ZeroBounceSDK = require('@zerobounce/zero-bounce-sdk');

function Registration() {

  const [noBasketDataError, setNoBasketDataError] = useState("");
  const [errors, setErrors] = useState({});
  const [dataSaved, setDataSaved] = useState(false);
  //const [isPending, setIsPending] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    newsletter: false,
    pa_post_code: "",
    pa_settlement: "",
    pa_street: "",
    deliveryType: "",
    post_number: "",
    diffBillAddress: false,
    bill_name: "",
    tax_number: "",
    ba_post_code: "",
    ba_settlement: "",
    ba_street: "",
  });
  const zeroBounce = new ZeroBounceSDK();
  zeroBounce.init(process.env.REACT_APP_ZEROBOUNCE_KEY);
  const wrongFill = {
    border: "3px solid red",
    color: "red",
    backgroundColor: "#cedc00",
  }
  const correctFill = {
    border: "none",
    color: "black",
    backgroundColor: "#cedc00",
  }

  useEffect(()=> {
    const basketObj = sessionStorage.getItem("basket");
      if (!basketObj || basketObj.length < 1) {
        setNoBasketDataError("A kosárban jelenleg nincs termék");
      }
  }, []);
  
  useEffect(() => {
    isAnExistingData();
    //testZeroBounce();
  }, []);

  function isAnExistingData() {
    let data = sessionStorage.getItem("customerData");
    if (data && data.length > 0) {
      setUserData(JSON.parse(data));
    }
  }

  async function ZeroBounceCheckMail() {
    try {
      if (userData.email.length > 5) {
        const {status} = await zeroBounce.validateEmail(userData.email);
        const emailField = document.querySelector("#email");
        if (status !== "valid") {
          setErrors(prev => ( {...prev, emailNotValid: "Nem valós email cím!"} ));
          emailField.style.border = "3px solid red";
          emailField.style.color = "red";
        } else {
          const { emailNotValid, ...rest} = errors;
          setErrors(rest);
          emailField.style.border = "0";
          emailField.style.color = "black";
        }
      }
    } catch (error) {
      console.error(error);
      //ha valamiért hibára fut az ellenőrzés...(nem működik az API vagy lefogyott credit...)
      const { emailNotValid, ...rest} = errors;
      setErrors(rest);
    }
  }

  /* async function checkEmail() {
    try {
      let email = userData.email;
      const url = `${process.env.REACT_APP_BACKEND_URL}/isAnExistingEmail/${email}`;
      const {data} = await axios.get(url, {email});
      //console.log(data);
      if (data.exists === 1 || data.exists === "1") {
        setErrors({...errors, emailExists: "Ez az email cím már foglalt!"});
      } else {
        if ("emailExists" in errors) {
          const { emailExists, ...newErrors} = errors;
          setErrors(newErrors);
        }
      }
    } catch (err) {
      console.log(err)
    }
  } */
  

  const handleChange = (e) => {
    const {name, value} = e.target;
    if (name === "deliveryType") {
      if (e.target.id === "home") {
        setUserData({...userData, [name]: e.target.id, post_number: ""});
      } else {
        setUserData({...userData, [name]: e.target.id, diffBillAddress: true});
        //Ha nem otthoni címre történik a kiszállítás, akkor mindenképpen meg kell adni számlázási címet is
        
      }
    } else if (name === "newsletter" || name === "diffBillAddress") {
      setUserData({...userData, [name]: e.target.checked});
    } else {
      setUserData({...userData, [name]: value});
    }
  }

  /* function checkPasswd(e) {
    let passwd = e.target.value;
    if (passwd.length < 8) {
      if (!("passTooShort" in errors)) {
        setErrors({...errors, passTooShort: "A jelszó túl rövid!"});
      }
    } else {
      //Ha a jelszó hossza megfelelő...
      if ("passTooShort" in errors) {
        const { passTooShort, ...newErrors} = errors;
        setErrors(newErrors);
      }
    }
  }

  function isPassEquals() {
    const passEquals = userData.passwd === userData.passwd_verify;
    if (!passEquals) {
      setErrors({...errors, passNotEquals: "A jelszavak nem egyeznek!"});
    } else {
      if ("passNotEquals" in errors) {
        const {passNotEquals, ...newErrors} = errors;
        setErrors(newErrors);
      }
    }
  } */

  const displayBillAddressSection = (e) => {
    //csak akkor történhessen változtatás, ha nem postai és nem postán maradó csomagról van szó
    if (userData.deliveryType !== "post" && 
      userData.deliveryType !== "post-point") {

        const billAddressSection = document.querySelector("#billAddress");
        if (e.target.checked) {
          billAddressSection.style.display = 'flex';
          billAddressSection.classList.remove("billAddress");
          billAddressSection.classList.add("dataCategory");
          setUserData({...userData, diffBillAddress: e.target.checked});
        } else {
          billAddressSection.style.display = 'none';
          billAddressSection.classList.remove("dataCategory");
          billAddressSection.classList.add("billAddress");
          //deleteBillAddressData();
          setUserData(prevUserData => {
            return {
              ...prevUserData,
              diffBillAddress: e.target.checked, 
              ba_post_code: "", 
              ba_settlement: "", 
              ba_street: "",
              bill_name: "",
              tax_number: "",
            };
          });
        }
      }
  }

  //Form Adatok mentése
  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(userData);
    setDataSaved(true);
    sessionStorage.setItem("customerData", JSON.stringify(userData));
  }

  const displayErrors = () => {
    let errorRows = [];
    let index = 0;
    for (let key in errors) {
      errorRows.push(<li key={index}>{errors[key]}</li>);
      index++;
    }
    return <ul>{errorRows}</ul>;
  }

  const haveToDisplay = () => {
    if (!userData.diffBillAddress) {
      return { display: "none"};
    }
    return {};
  }

  function checkTaxNumberValidity() {
    //ha üresen van hagyva, akkor ne vizsgálja
    if (userData.tax_number.length > 0) {
      let txNr = new RegExp();
      txNr = /^[\d]{8}-[1-5]-[\d]{2}$/;
      if (!txNr.test(userData.tax_number)) {
        //console.log("Hiba: az adószám formátuma nem megfelelő.");
        setErrors(prevErrors => {
          return {...prevErrors, taxNumberNotValid: "Az adószám formátuma nem megfelelő."};
        });
      } else {
        if ("taxNumberNotValid" in errors) {
          if (Object.keys(errors).length > 1) {
            //ha más hiba is van
            const {taxNumberNotValid, ...rest} = errors;
            setErrors(rest);
          } else {
            //ha más hiba nincs
            setErrors({});
          }
        }
      }
    } else {
      //ha korábban meg volt adva és a hiba mentésre került:
      if ("taxNumberNotValid" in errors) {
        if (Object.keys(errors).length > 1) {
          //ha más hiba is van
          const {taxNumberNotValid, ...rest} = errors;
          setErrors(rest);
        } else {
          //ha más hiba nincs
          setErrors({});
        }
      }
    }
  }

  return (
    <>
    {noBasketDataError !== "" ? 
      (<div className='error-display'>
        {noBasketDataError}
      </div>
      )
      :
      (<>
        <h1>Rendelési adatok megadása</h1>
        <div className="regWrapper">
          <div>
            <form className="regForm" onSubmit={e => handleSubmit(e)}>
              <section className="dataCategory">
                <h2>Alapadatok</h2>
                <Input 
                  id="name" 
                  type="text" 
                  name="name"
                  value={userData.name} 
                  onChange={e => handleChange(e)}
                  placeholder="Teljes név"
                  style={{ backgroundColor: "#cedc00" }}
                  required
                />
                <Input 
                  id="email" 
                  type="name"
                  name="email"
                  value={userData.email} 
                  onChange={e => handleChange(e)}
                  /* onBlur={() => ZeroBounceCheckMail()} */
                  placeholder="Email cím"
                  style={{ backgroundColor: "#cedc00" }}
                  required
                />
                <Input 
                  id="phone" 
                  type="text" 
                  name="phone"
                  value={userData.phone} 
                  onChange={e => handleChange(e)}
                  placeholder="Telefonszám"
                  style={{ backgroundColor: "#cedc00" }}
                  required
                />
                <div>
                  <Input 
                    id="newsletter" 
                    type="checkbox" 
                    name="newsletter"
                    checked={userData.newsletter} 
                    onChange={e => handleChange(e)}
                    style={{ backgroundColor: "#cedc00", marginRight: ".5rem", color: "black" }}
                  />
                  <Label for="newsletter">Hírlevél</Label>
                </div>
              </section>
    
              <section className="dataCategory">
                <h2>Postázási cím</h2>
                  <div className='delivery-options'>
                    <div>
                      <input 
                        type="radio" 
                        id="home" 
                        name="deliveryType" 
                        checked={userData.deliveryType === "home"}
                        onChange={e => handleChange(e)}
                        required
                        />
                      <label htmlFor="home">Házhozszállítás (MPL)</label>
                    </div>
                    <div>
                      <input 
                        type="radio" 
                        id="post" 
                        name="deliveryType"
                        checked={userData.deliveryType === "post"}
                        onChange={e => handleChange(e)} 
                        required
                      />
                      <label htmlFor="post">
                        Postán maradó {""}
                        <span className="ba-required">
                          (számlacím kötelező)
                        </span>
                      </label>
                    </div>
                    <div>
                      <input 
                        type="radio" 
                        id="post-point" 
                        name="deliveryType"
                        checked={userData.deliveryType === "post-point"}
                        onChange={e => handleChange(e)} 
                        required
                      />
                      <label htmlFor="post-point">
                        Postapont {""}
                        <span className="ba-required">
                          (számlacím kötelező)
                        </span>
                      </label>
                    </div>
                  </div>
                <Input 
                  id="pa_post_code" 
                  type="number"
                  maxLength="4"
                  name="pa_post_code"
                  value={userData.pa_post_code} 
                  onChange={e => handleChange(e)}
                  placeholder="Irányítószám"
                  style={{ backgroundColor: "#cedc00" }}
                  required
                />
                <Input 
                  id="pa_settlement" 
                  type="text"
                  name="pa_settlement"
                  value={userData.pa_settlement} 
                  onChange={e => handleChange(e)}
                  placeholder="Település"
                  style={{ backgroundColor: "#cedc00" }}
                  required
                />
                <Input 
                  id="pa_street" 
                  type="text"
                  name="pa_street"
                  value={userData.pa_street} 
                  onChange={e => handleChange(e)}
                  placeholder="Közterület neve"
                  style={{ backgroundColor: "#cedc00" }}
                  required
                />
                {(userData.deliveryType === "post" || userData.deliveryType === "post-point") && (
                  <Input 
                    id="pa_details" 
                    type="text"
                    name="post_number"
                    value={userData.post_number} 
                    onChange={e => handleChange(e)}
                    placeholder="Posta vagy postapont száma"
                    style={{ backgroundColor: "#cedc00" }}
                    required={userData.deliveryType === "post" || userData.deliveryType === "post-point"}
                  />
                )}
                
              </section>
    
              <section>
                <Input 
                  id="diffBillAddress"
                  name="diffBillAddress"
                  type="checkbox"
                  checked={userData.diffBillAddress}
                  onChange={e => displayBillAddressSection(e)}
                  style={{ backgroundColor: "#cedc00", marginRight: ".5rem" }}
                />
                <Label for="diffBillAddress">
                  Eltérő számlázási címet adok meg
                </Label>
              </section>
                
              <section style={haveToDisplay()} id="billAddress" className="dataCategory">
                <h2>Számlázási adatok</h2>
                <Input 
                  id="bill_name"
                  type="text"
                  name="bill_name"
                  value={userData.bill_name} 
                  onChange={e => handleChange(e)}
                  placeholder="Számlázási név"
                  style={{ backgroundColor: "#cedc00" }}
                  required={userData.diffBillAddress}
                />
                <Input 
                  id="tax_number"
                  type="text"
                  name="tax_number"
                  value={userData.tax_number} 
                  onChange={e => handleChange(e)}
                  onBlur={checkTaxNumberValidity}
                  placeholder="Adószám (cég esetén)"
                  maxLength={13}
                  /* style={{ backgroundColor: "#cedc00" }} */
                  style={"taxNumberNotValid" in errors ? 
                    wrongFill : correctFill}
                  /* required={userData.diffBillAddress} */
                />
                <Input 
                  id="ba_post_code" 
                  type="number"
                  maxLength="4"
                  name="ba_post_code"
                  value={userData.ba_post_code} 
                  onChange={e => handleChange(e)}
                  placeholder="Irányítószám"
                  style={{ backgroundColor: "#cedc00" }}
                  required={userData.diffBillAddress}
                />
                <Input 
                  id="ba_settlement" 
                  type="text"
                  name="ba_settlement"
                  value={userData.ba_settlement} 
                  onChange={e => handleChange(e)}
                  placeholder="Település"
                  style={{ backgroundColor: "#cedc00" }}
                  required={userData.diffBillAddress}
                />
                <Input 
                  id="ba_street" 
                  type="text"
                  name="ba_street"
                  value={userData.ba_street} 
                  onChange={e => handleChange(e)}
                  placeholder="Közterület neve"
                  style={{ backgroundColor: "#cedc00" }}
                  required={userData.diffBillAddress}
                />
              </section>
    
              {Object.keys(errors).length > 0 && (
                <section className="regFormErrorDisplay">
                <h2>Kitöltési hibák</h2>
                {displayErrors()}
              </section>
              )}
              
              <Button 
                id="submitBtn"
                disabled={Object.keys(errors).length > 0}
              >Adatok mentése
              </Button>
            </form>
            {dataSaved && (
              <div style={{ margin: "2rem auto", }}>
                <Link to={"/rendeles-osszesito/"}>Tovább a rendelés összesítő oldalra</Link>
              </div>
            )}
          </div>
        </div>
      </>
      )
    }
    </>
  );
}

export default Registration;
