import React, {useState} from 'react';

function Kapcsolat() {

  const [email, setEmail] = useState("");

  function generateEmail(e) {
    setTimeout(() => {
      const email = <a href='mailto:shop@boznanszkykes.hu'>shop@boznanszkykes.hu</a>;
      setEmail(email);
    }, 200);
  }

  return (
    <div>
      <ul className='footer-nav'>
        <li>
          <i className="fa-solid fa-house"></i>
          <span className='contact-address'>
            9500 Celldömölk <br /> Széchenyi utca 782/A/3 1. épület
          </span>
          
        </li>
        <li onLoad={generateEmail()}>
          <i className="fa-regular fa-envelope"></i>
          <span style={{ marginLeft: "1rem"}}>
            {email}
          </span>
        </li>
      </ul>      
    </div>
  )
}

export default Kapcsolat;
