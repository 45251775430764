import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Spinner} from 'reactstrap';
import { priceFormatter } from '../utils/utils';

function Delivery() {

   const [loading, setLoading] = useState(false);
   const [deliveryData, setDeliveryData] = useState([]);
   const [error, setError] = useState("");

   useEffect(() => {
      getDeliveryData();
   }, [])

   async function getDeliveryData() {
      setLoading(true);
      try {
         const url = `${process.env.REACT_APP_BACKEND_URL}/deliveryprices`;
         const {data} = await axios.get(url);
         if (Array.isArray(data) && data.length > 0) {
            //console.log(data);
            setDeliveryData(data);
         } else {
            setError("A kiszállítási adatok nem érhetőek el.");
         }
      } catch(err) {
         //console.log(err);
         setError(err.message);
      } finally {
         setLoading(false);
      }
   }

   function renderTableHeader() {
      let header = <thead>
         <tr className='table-info'>
            <th>Rendelési érték</th>
            <th>Előre utalás</th>
            <th>Utánvét</th>
            <th>Kézbesítő</th>
         </tr>
      </thead>;
      return header;
   }

   function renderDeliveryData() {
      let rows = [];
      for (let i=0; i < deliveryData.length; i++) {
         let row = <tr key={i}>
            {i === deliveryData.length-1 ? 
               (<td style={{ color: "gray" }}>{priceFormatter(deliveryData[i].price_from)} felett
               </td>) 
               : 
               (<td style={{ color: "gray" }}>{priceFormatter(deliveryData[i].price_from)} és {priceFormatter(deliveryData[i].price_to)} között
               </td>)
            }
            <td className='center'>{priceFormatter(deliveryData[i].forward_paying_price)}</td>
            <td className='center'>{priceFormatter(deliveryData[i].cash_on_delivery_price)}</td>
            <td>MPL Posta Logisztika</td>
         </tr>;
         rows.push(row);
      }
      return <tbody>{rows}</tbody>;
   }

  return (
    <div className='delivery-wrapper'>
      {loading ?
      (<Spinner>Loading</Spinner>)
      :
      (deliveryData.length > 0 ? 
         (<><div className='flexContainer'>
            <table className='deliveryPrices'>
               <caption>Kiszállítási díjaink</caption>
               {renderTableHeader()}
               {renderDeliveryData()}
            </table>
         </div>
         <div>
            <ul className='deliveryComment'>
               <li>Kiszállítás kizárólag Magyarország területén belül az MPL Posta Logisztikával.</li>
               <li>Előre utalásos fizetés csak készleten lévő termék rendelése esetén lehetséges.</li>
               <li>Előre utalásos fizetés esetén a rendelés feldolgozását követően megküldjük Önnek a számlát és az utalással kapcsolatos adatokat.</li>
               <li>Nem készleten lévő termék esetén a kiszállítási időt a megrendelést követően egyeztetjük Önnel.</li>
            </ul>
         </div>
         </>
         ) 
         : 
         (<div>{error}</div>)
      )}
    </div>
  )
}

export default Delivery;
