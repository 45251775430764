import React, {useEffect} from 'react';
import Header2 from './Header2';
import Footer from '../sections/Footer';
import TopHeader from './TopHeader';
import { scrollTop, hideMobMenu } from '../utils/utils';

function Layout( { content } ) {
  useEffect(() => {
    scrollTop();
    hideMobMenu();
  });

  return (
    <div>
        <TopHeader />
        <Header2 />
            <div className='main'>{content}</div>
        <Footer />
    </div>
  )
}

export default Layout;
