import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import Add2Basket from '../components/Add2Basket';
import { priceFormatter, imgHost } from '../utils/utils';
import 'lightbox2/dist/css/lightbox.min.css';
import 'lightbox2/dist/js/lightbox-plus-jquery';
import Delivery from '../components/Delivery';

function TermekAdatlap() {

   const params = useParams();
   const productId = params.productId;
   const [productData, setProductData] = useState([]);
   const [imageData, setImageData] = useState([]);
   const [isLoadingD, setIsLoadingD] = useState(false);
   const [isLoadingI, setIsLoadingI] = useState(false);
   const [error, setError] = useState({
      dataError: null,
      imageError: null
   });

   useEffect(() => {
      fetchData();
      fetchImages();
   }, []);

   async function fetchData() {
      setIsLoadingD(true);
      try {
         const url = `${process.env.REACT_APP_BACKEND_URL}/termek/${productId}`;
         const {data} = await axios.get(url);
         if (Array.isArray(data) && data.length > 0) {
            //console.log(data[0]);
            setProductData(calculateSalePriceIfNeeded(data[0]));
         } else {
            setError({...error, dataError: "Ezzel az azonosítóval nem létezik termék."});
         }
      } catch(err) {
         //console.log(err);
         setError({...error, dataError: err.response.data.msg });
      } finally {
         setIsLoadingD(false);
      }
   }

   async function fetchImages() {
      setIsLoadingI(true);
      try {
         const url = `${process.env.REACT_APP_BACKEND_URL}/termekkepek/${productId}`;
         const {data} = await axios.get(url);
         if (Array.isArray(data) && data.length > 0) {
            //console.log(data);
            setImageData(data);
         } else {
            setError({...error, imageError: "Sajnos ehhez a termékhez nincs elérhető kép."});
         }
      } catch(err) {
         console.log(err);
         setError({...error, imageError: err.message });
      } finally {
         setIsLoadingI(false);
      }
   }

   function getStoreInfo(stock) {
      if (stock > 0) {
         return `${stock} db`;
      }
      return "Nincs készleten, de rendelhető";
   }

   function renderData() {
      const dataTable = <tbody>
         <tr>
            <td>Teljes hossz:</td>
            <td className='datafield'>{productData.full_length} mm</td>
         </tr>
         <tr>
            <td>Penge hossza:</td>
            <td className='datafield'>{productData.blade_length} mm</td>
         </tr>
         <tr>
            <td>Penge vastagsága:</td>
            <td className='datafield'>{productData.blade_width} mm</td>
         </tr>
         <tr>
            <td>Penge anyaga:</td>
            <td className='datafield'>{productData.blade_material}</td>
         </tr>
         <tr>
            <td>Markolat anyaga:</td>
            <td className='datafield'>{productData.handle_material}</td>
         </tr>
         <tr>
            <td>Keménység:</td>
            <td className='datafield'>{productData.hardness} HRC</td>
         </tr>
         <tr>
            <td>Leírás:</td>
            <td className='datafield desc'>{productData.description}</td>
         </tr>
         <tr>
            <td>Készlet:</td>
            <td className='datafield'>
               {getStoreInfo(productData.stock)}
            </td>
         </tr>
      </tbody>;
      return <table className='datasheet'>{dataTable}</table>;
   }

   function calculateSalePriceIfNeeded(product) {
      if ("sale_percentage" in product) {
         if (product.sale_percentage > 0) {
            let discount = product.sale_percentage / 100 * product.price;
            let discountedPrice = product.price - discount;
            return {...product, discountedPrice};
         }
      }
      return {...product, discountedPrice: product.price};
   }

   function renderPrice() {
      let onSale = productData.sale_percentage > 0 ? true : false;
      if (onSale) {
         return (
            <div className='price-wrapper'>
               <div className='normalPrice'>{priceFormatter(productData.price)}</div>
               <div className='salePrice'>{priceFormatter(productData.discountedPrice)}</div>
               <div className='salePerc'>- {productData.sale_percentage}%</div>
               <div className='saleDeadline'>{productData.deadline_sh}-ig</div>
            </div>
         );
      }
      return <div className='price-wrapper'>{priceFormatter(productData.price)}</div>;
   }

   function renderProductImages() {
      const photos = imageData.map((item, ind) => 
         <div key={ind}>
            <a 
               href={imgHost + item.image_path}
               data-lightbox="roadtrip"
            >
               <img 
                  src={imgHost + item.thumbnail_path} 
                  alt={`{kep-${ind}}`}   
               />
            </a>
         </div>
      )
      return photos;
   }

  return (
   <>
      <h1>{Object.keys(productData).length > 0 && productData.name}</h1>
      <div className='product-wrapper'>
         <div className='product-images'>
            {isLoadingI ? 
               (<Spinner></Spinner>) 
               : 
               (error.imageError || error.dataError ? 
                  (<div className='no-content'>{error.imageError}</div>) 
                  : 
                  (imageData.length > 0 && 
                     (<div>{renderProductImages()}
                        <div className='help'>
                           Nagyobb mérethez kattintson a képre
                        </div>
                     </div>)
                  )
               )
            }
            
         </div>
         <div className='product-data'>
            {isLoadingD ? 
               (<Spinner></Spinner>) 
               : 
               (error.dataError ? 
                  (<div className='no-content'>{error.dataError}</div>) 
                  : 
                  (Object.keys(productData).length > 0 && renderData())
               )
            }
         </div>
      </div>
      <div className='product-price'>
         {Object.keys(productData).length > 0 && renderPrice()}
      </div>
      {(Object.keys(productData).length > 0) && (
         <div>
            <Add2Basket product={productData} />
         </div>
      )}
      <Delivery />
   </>
  )
}

export default TermekAdatlap;
