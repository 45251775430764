import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Spinner } from 'reactstrap';

function Appeal( {aloneInTheRow} ) {

  const [appeals, setAppeals] = useState([]);
  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);
  const [noContent, setNoContent] = useState(false);

  useEffect(() => {
    fetchAppeals();
  }, []);

  async function fetchAppeals() {
    setPending(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/felhivas/`;
          const {data} = await axios.get(url);
          if (Array.isArray(data) && data.length > 0) {
            setAppeals(data);
          } else {
            setError("Jelenleg nincs hír.");
            setNoContent(true);
          }
    } catch (err) {
        //console.log(err);
        let errorMsg = err?.response?.data?.msg ?? "Jelenleg nincs elérhető hír.";
        setError(errorMsg);
        setNoContent(true);
    } finally {
      setPending(false);
    }
  }

  function renderAppeals() {
    const appealCards = appeals.map((item, index) => (
      <div className='appeal-card' key={index}>
        <div className='appeal-title'>{item.title}</div>
        <div className='appeal-body'>{item.body}</div>
        <div className='appeal-date'>{item.start_date}</div>
      </div>)
    );
    return appealCards;
  }
   
  return (
      
      !noContent && (
        <div>
          <h1>Híreink</h1>
          <div className={aloneInTheRow? 
            "appeals-wrapper-row" : "appeals-wrapper-col"}
          >
            {pending ? 
              (<Spinner>Loading...</Spinner>)
              :
              (appeals.length > 0 ? 
                (renderAppeals())
                :
                (<div className='no-content'>{error}</div>)
              )
            }
          </div>
        </div>
      )
      
  );
}

export default Appeal;
