import React, {useEffect} from 'react';

function Aszf() {

  useEffect(()=>{
    const script = document.createElement('script');
    script.src = "//admin.fogyasztobarat.hu/e-api.js";
    script.type = "text/javascript";
    script.setAttribute('data-id', 'ATCKFA84');
    script.setAttribute('data-type', 'aszf');
    script.setAttribute('id', 'fbarat-embed');
    script.async = true;
    const existingScript = document.getElementById("barat_script");
    existingScript.parentNode.insertBefore(script, existingScript);
  }, []);

  

  return (
    <div className='consumer-law-infos'>
      <h1>Általános szerződési feltételek</h1>
      <div className='law-text-wrapper'>
        <script id="barat_script"></script>
      </div>
    </div>
  )
}

export default Aszf;
