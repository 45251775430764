import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Spinner } from 'reactstrap';
import { knifeTypeFormatter } from '../utils/utils';

function CategoryStat() {

   const [stat, setStat] = useState([]);
   const [loading, setIsLoading] = useState(false);
   const [error, setError] = useState("");

   useEffect(() => {
      async function fetchStat() {
         setIsLoading(true);
         try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/termekek/stat/`;
            const {data} = await axios.get(url);
            if (Array.isArray(data) && data.length > 0) {
               //data.sort((a, b) => { return b.quantity - a.quantity });
               setStat(data);
               //console.log(data);
            } else {
               setError("Jelenleg nem érhető el statisztika.");
            }
         } catch (err) {
            console.log(err);
            setError(`Hiba: ${err}`);
         } finally {
            setIsLoading(false);
         }
      }
      fetchStat();
   }, []);

   function renderStat() {
      let stats = stat.map((item, ind) => 
         <div className='cat-wrapper' key={ind}>
            <div className='cat-type'>{knifeTypeFormatter(item.type)}</div>
            <div className='cat-quantity'>{item.quantity} db</div>
         </div>
      );
      return stats;
   }

  return (
   <div className='stat-wrapper'>
      {loading ? 
         (<Spinner>Loading...</Spinner>) 
         : 
         (stat.length > 0 ? 
            (renderStat()) 
            : 
            (<p>{error}</p>)
         )
      }
   </div>
  )
}

export default CategoryStat;
