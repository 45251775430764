import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Spinner } from 'reactstrap';

function Guestbook() {

   const [comments, setComments] = useState("");
   const [error, setError] = useState("");
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      getComments();
   }, []);

   async function getComments() {
      setLoading(true);
      try {
         const url = `${process.env.REACT_APP_BACKEND_URL}/guestbook`;
         const {data} = await axios.get(url);
         if (Array.isArray(data) && data.length > 0) {
            //console.log(data);
            setComments(data);
         } else {
            setError("A vendégkönyv még nem tartalmaz bejegyzést!");
         }
      } catch (err) {
         //console.log(err);
         setError(err.message);
      } finally {
         setLoading(false);
      }
   }

   function renderComments() {
      let commentsArr = comments.map(comment => 
         <div key={comment.id} className="gbComment">
            <div className="gbCommentBody">{comment.comment}</div>
            <div className="gbCommentAuthor">{comment.customer}</div>
         </div>);
      return commentsArr;
   }

  return (
    <section id="guestbook-comments">
      {loading ?
      (<Spinner>Loading</Spinner>)
      :
      (comments.length > 0 ? 
         (<div className='about-us'>
            <h1>Rólunk írták</h1>
            <div className="gbCommentWrapper">
               {renderComments()}
            </div>
         </div>)
         : 
         (<div>{error}</div>)
      )}
    </section>
  )
}

export default Guestbook;
