import React from 'react';
import CategoryStat from './CategoryStat';

function Header2() {
  return (
   <div className='header-picture'>
      <img 
         src={process.env.PUBLIC_URL + '/assets/bicska-bg-1600.webp'} 
         alt="Boznánszky bicska" 
      />
      <div className='brand-container'>
            <div className='brand'>Boznánszky</div>
      </div>

      <div className='stat-absolute'>
         <CategoryStat />
      </div>
   </div>
   
  );
}

export default Header2;
