import './App.css';
import React, { useState, createContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import Layout from './components/Layout';
import Guestbook from './sections/Guestbook';
import Registration from './components/Registration';
import Delivery from './components/Delivery';
import Galeria from './pages/Galeria';
import Kapcsolat from './components/Kapcsolat';
import Bicskak from './pages/Bicskak';
import Introduction from './sections/Introduction';
import Adatvedelem from './pages/Adatvedelem';
import Aszf from './pages/Aszf';
import Elallasi from './pages/Elallasi';
import Fogyasztoi from './pages/Fogyasztoi';
import TermekAdatlap from './pages/TermekAdatlap';
import Kosar from './pages/Kosar';
import ProductScroller from './components/ProductScroller';
import InstockOnsale from './sections/InstockOnsale';
import OrderSummary from './components/OrderSummary';
import OrderConfirmation from './components/OrderConfirmation';
import Kereso from './components/Kereso';
import SearchResults from './components/SearchResults';

export let BasketContext = createContext(0);

function App() {

  const [itemsInBasket, setItemsInBasket] = useState(0);

  return (
    <div className="App">
      <BasketContext.Provider value={{itemsInBasket, setItemsInBasket}}>
      <ScrollToTop />
      <Routes>
        <Route path="/" 
          element={<Layout content={<><ProductScroller /><Kereso /><InstockOnsale /><Introduction /><Guestbook /><Delivery /></>}/>}>
        </Route>
        
        <Route path="/teszt/" 
          element={<Layout content={<Delivery />} />}>
        </Route>

        <Route path="/termekek/" 
          element={<Layout content={<Bicskak />} />}>
        </Route>

        <Route path="/bicskak/" 
          element={<Layout content={<Bicskak />} />}>
        </Route>

        <Route path="/mives-bicskak/" 
          element={<Layout content={<Bicskak />} />}>
        </Route>

        <Route path="/konyhakesek/" 
          element={<Layout content={<Bicskak />} />}>
        </Route>

        <Route path="/torok/" 
          element={<Layout content={<Bicskak />} />}>
        </Route>

        <Route path="/adatlap/:productId" 
          element={<Layout content={<TermekAdatlap />} />}>
        </Route>

        <Route path="/vendegkonyv/" 
          element={<Layout content={<Guestbook />} />}>
        </Route>

        <Route path="/galeria/" 
          element={<Layout content={<Galeria />} />}>
        </Route>

        <Route path="/kiszallitas/" 
          element={<Layout content={<Delivery />} />}>
        </Route>

        <Route path="/kapcsolat/" 
          element={<Layout content={<Kapcsolat />} />}>
        </Route>

        <Route path="/aszf/" 
          element={<Layout content={<Aszf />} />}>
        </Route>

        <Route path="/adatvedelem/" 
          element={<Layout content={<Adatvedelem />} />}>
        </Route>

        <Route path="/fogyasztoi/" 
          element={<Layout content={<Fogyasztoi />} />}>
        </Route>

        <Route path="/elallasi/" 
          element={<Layout content={<Elallasi />} />}>
        </Route>

        <Route path="/kosar/" 
          element={<Layout content={<Kosar />} />}>
        </Route>

        <Route path="/rendelesi-adatok/" 
          element={<Layout content={<Registration />} />}>
        </Route>

        <Route path="/rendeles-osszesito/" 
          element={<Layout content={<OrderSummary />} />}>
        </Route>

        <Route path="/rendeles-visszaigazolas/" 
          element={<Layout content={<OrderConfirmation />} />}>
        </Route>

        <Route path="/kereses" 
          element={<Layout content={<SearchResults />} />}>
        </Route>
  
      </Routes>
      </BasketContext.Provider>
    </div>
  );
}

export default App;
