import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";

function MyModal({ title, body, isOpen, toggle }) {

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Button color="info" onClick={toggle}>
            Vásárlás folytatása
          </Button>

          <Link to={"/kosar/"}>
            <Button color="warning">
              Ugrás a kosárhoz
            </Button>{" "}
          </Link>
          
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default MyModal;
