import React, {useEffect, useState, useContext} from 'react';
import MyModal from './MyModal';
import { BasketContext } from '../App';
import { isItAllowedToInc } from '../utils/utils';

function Add2Basket( {product} ) {

   const {itemsInBasket, setItemsInBasket} = useContext(BasketContext);
   const [quantity, setQuantity] = useState(1);
   const [basketMsg, setBasketMsg] = useState("");

   const [modal, setModal] = useState(false);
   const toggle = () => setModal(!modal);

   const handleChange = (e) => {
      let q = e.target.value;
      if (q > 0 && q < 11) {
         setQuantity(q);
      }  
   }

   useEffect(() => {
      if (basketMsg !== "") {
         toggle();
      }
   }, [basketMsg]);

   useEffect(() => {
      if (!modal) {
         setBasketMsg("");
      }
   }, [modal])

   function moveToBasket() {
      let wasInTheBasket = false;
      //1, megvizsgálni, hogy van-e már valami a kosárban
      const basket = sessionStorage.getItem("basket");
      if (basket && basket.length > 0) {
         //tömbbé alakítjuk
         let basketArray = JSON.parse(basket);
         //végigiterálunk a tömbön és ha találunk olyan termékazonosítóval, ami egyezik az aktuálissal, akkor csak a mennyiséget kell updatelni
         basketArray.forEach(element => {
            if (Number(product.knives_id) === Number(element.productId)) {
               let sum = element.quantity + Number(quantity);
               if (sum <= 10 && 
                  isItAllowedToInc(product.sale_percentage, 
                     product.stock, sum)) 
               {
                  element.quantity = sum;
                  setBasketMsg(`A termék száma ${sum}-ra változott a kosárban.`);
               } else {
                  setBasketMsg('Egy termékből maximum 10 db lehet a kosárban! Készleten lévő akciós termék esetén maximálisan a készlet nagysága kerülhet a kosárba.');
               }
               wasInTheBasket = true;
               //console.log("a kosárban volt.");
            }
         });
         if (!wasInTheBasket) {
            //ha még nem szerepelt termék ezzel az ID-vel a kosárban
            //let newItem = { productId, quantity: Number(quantity) };
            
            //Készleten lévő akciós termék esetén maximálisan a készlet nagysága kerülhet a kosárba.
            if (!isItAllowedToInc(product.sale_percentage, 
               product.stock, quantity)) 
            {
               setBasketMsg('Készleten lévő akciós termék esetén maximálisan a készlet nagysága kerülhet a kosárba.');
               return;
            }
            let newItem = { 
               productId: product.knives_id,
               productName: product.name,
               productPrice: Number(product.price),
               productDiscountedPrice: Number(product.discountedPrice),
               productSale: Number(product.sale_percentage),
               productInStock: Number(product.stock),
               quantity: Number(quantity),
            };
            basketArray.push(newItem);
            setBasketMsg(`${quantity} db termék a kosárba került.`);
         }
         sessionStorage.setItem("basket", JSON.stringify(basketArray));
         
      } else {
         //ha a kosár még üres

         /* console.log("üres a kosár" + `Akció: ${product.sale_percentage}, 
         Készlet: ${product.stock}, Mennyiség: ${quantity}`); */

         //Készleten lévő akciós termék esetén maximálisan a készlet nagysága kerülhet a kosárba.
         if (!isItAllowedToInc(product.sale_percentage, 
            product.stock, quantity)) 
         {
            setBasketMsg('Készleten lévő akciós termék esetén maximálisan a készlet nagysága kerülhet a kosárba.');
            return;
         }
         let newItem = { 
            productId: product.knives_id,
            productName: product.name,
            productPrice: Number(product.price),
            productDiscountedPrice: Number(product.discountedPrice),
            productSale: Number(product.sale_percentage), 
            productInStock: Number(product.stock),
            quantity: Number(quantity), 
         };
         let basket = [];
         basket.push(newItem);
         sessionStorage.setItem("basket", JSON.stringify(basket));
         setBasketMsg(`${quantity} db termék a kosárba került.`);
      }
      setItemsInBasket(prev => prev + 1);
   }


  return (
    <div className='basket-wrapper'>
      {modal && (
         <MyModal 
            title="Információ"
            body={basketMsg} 
            isOpen={modal} 
            toggle={toggle} 
         />)
      }
      <label htmlFor="basket-quantity">Mennyiség:</label>
      <input 
         id="basket-quantity"
         type="number" 
         className='quantity'
         min={1}
         max={10}
         value={quantity}
         onChange={e => {handleChange(e)}}
      />
      <button className='add2basket' onClick={moveToBasket}>Kosárba</button>
    </div>
  )
}

export default Add2Basket;
