import React from 'react';
import Kapcsolat from '../components/Kapcsolat';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className='footer'>
      <div className='block-wrapper'>
        <div className='fogyaszto-barat-banner'>
          <img 
            src={`${process.env.PUBLIC_URL}/assets/fogybarat_banner_150.png`} 
            width="150"
            height="150"
            alt='fogyaszto-barat-banner'
          />
        </div>
        <div className='useful-infos'>
          <h3>Hasznos Információk</h3>
          <ul className='footer-nav centered'>
            <li><Link to={"/aszf/"}>ÁSZF</Link></li>
            <li><Link to={"/adatvedelem/"}>Adatvédelem</Link></li>
            <li><Link to={"/fogyasztoi/"}>Cookie tájékoztató</Link></li>
            {/* <li><Link to={"/elallasi/"}>Elállási nyilatkozat</Link></li> */}
          </ul>
        </div>
        <div className='contact'>
          <h3>Kapcsolat</h3>
          <Kapcsolat />
        </div>
      </div>
      <div className='copyright'>
        &copy; Boznánszkykés 2023
        &nbsp;|&nbsp;
        <span className='author'>created by Tamás Somlói</span>
      </div>
    </footer>
  )
}

export default Footer;
