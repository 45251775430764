import React, { useState, useEffect } from "react";
import axios from "axios";
import {Spinner} from "reactstrap";
import { useLocation, Link } from "react-router-dom";
import { isItInStock, 
  isItOnSale, 
  getShortName, 
  imgHost } from "../utils/utils";
import PenknifeFilter from "../components/PenknifeFilter";

let pageHeading = "";

function Bicskak() {
  const [isLoading, setIsLoading] = useState(false);
  const [penknifes, setPenknives] = useState([]);
  const [error, setError] = useState("");
  const location = useLocation();
  const currentPath = location.pathname;
  const productType = getProductTypeFromPath(currentPath);
  const [selectedSubcat, setSelectedSubcat] = useState("");
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    fetchPenknives();
  }, [currentPath]);

  useEffect(()=>{
    filterBySelectedSubcat();
  }, [selectedSubcat]);

  /* useEffect(()=>{
    console.log(filtered);
  }, [filtered]); */

  async function fetchPenknives() {
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/termekek/${productType}`;
      const { data } = await axios.get(url);
      if (Array.isArray(data) && data.length > 0) {
        //console.log(data);
        setPenknives(data);
        setFiltered(data);
      } else {
        setError("Úgy tűnik jelenleg nincs elérhető bicska.");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  function getProductTypeFromPath(path) {
    switch (path) {
      case "/bicskak/":
        pageHeading = "Bicskák";
        return "penknife";
      case "/mives-bicskak/":
        pageHeading = "Míves bicskák";
        return "wright_penknife";
      case "/konyhakesek/":
        pageHeading = "Konyhakések";
        return "kitchen_knife";
      case "/torok/":
        pageHeading = "Tőrök";
        return "dagger";
      default:
        return "";
    }
  }

  function renderProducts() {
    if (filtered.length === 0) 
      return <div className="nothing-find">Nincs találat</div>;

    let cards = filtered.map((item, ind) => (
      <Link 
        to={`/adatlap/${item.id_knives}`} 
        className='scrollerLink' 
        key={ind}
      >
        <div className="card-item" key={ind}>
          <div style={{ height: "135px"  }}>
            <img alt="Nincs kép" src={imgHost + item.thumbnail_path} />
          </div>
          <div className='p-info'>
            <span className={item.stock > 0 ? "in-stock" : ""}>
              {isItInStock(item.stock)}
            </span>
            {item.sale_percentage > 0 && 
              <div className='onsale'>
                -{item.sale_percentage} %
              </div>}
          </div>
          <div className='p-name'>{getShortName(item.name)}</div>
          <div className='p-price'>
            {isItOnSale(item.sale_percentage, item.price)}
          </div>
         {/* <Card
            color="dark"
            style={{
               width: "240px",
               height: "25rem",
               outline: "none",
               border: "none",
            }}
         >
          <div style={{ height: "135px"  }}>
            <img alt="Nincs kép" src={imgHost + item.thumbnail_path} />
          </div>
          
          <CardBody className="card-body">
            <CardTitle className="card-title">{item.name}</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                {priceFormatter(item.price)}
              </CardSubtitle>
              <div className='p-info'>
                {isItInStock(item.stock)}
                {item.sale_percentage > 0 && 
                  <div className='onsale'>
                    -{item.sale_percentage} %
                  </div>}
               </div>
               <div className='p-name'>{getShortName(item.name)}</div>
               <div className='p-price'>
                {isItOnSale(item.sale_percentage, item.price)}
               </div>
            <CardText className="card-text">
              {item.subcategory_name}<br />
              {item.description}
            </CardText>
              <Link to={`/adatlap/${item.id_knives}`}>
                <Button>Megnézem</Button>
              </Link>
          </CardBody>
        </Card> */}
        </div>
      </Link>
    ));
    return <div className="card-container">{cards}</div>;
  }

  function filterBySelectedSubcat() {
    if (selectedSubcat !== "all") {
      let onlyThisSubcat = penknifes.filter(item => 
        Number(item.type_subcategory) === Number(selectedSubcat)
      );
      setFiltered(onlyThisSubcat);
    } else {
      setFiltered(penknifes);
    }
  }

  return (
    <div>
      {isLoading ? 
      (<Spinner>Loading</Spinner>) 
      : 
      penknifes.length > 0 ? 
         (<div className="productList-wrapper">
            <h1>{pageHeading}</h1>
            {productType === "penknife" && 
              <PenknifeFilter select={setSelectedSubcat} />
            }
            {renderProducts()}
          </div>
          ) 
         : 
         (<div>{error}</div>)
      }
    </div>
  );
}

export default Bicskak;
