import React from 'react';

function Introduction() {
  return (
   <div className='comp-wrapper'>
      <h1>Bemutatkozás</h1>
      <div className='intro-wrapper'>
         <div>
            <img src="../../assets/introduction.jpg" alt="bemutatkozas" className='intro-img' />
         </div>
         <div className='introParagraphs'>
            <p>
            Boznánszky László vagyok, családunkban 3. generációs kézműves késes. A mesterséget nagyapámtól, Boznánszky Ferenc késes és köszörűs mestertől és édesapámtól, Boznánszky László késes és köszörűs mestertől tanultam. Mindent megteszek azért, hogy megteremtsem annak a lehetőségét, hogy Benedek és Barnabás fiaim is folytathassák e szép családi hagyományt.
            </p>

            <p>
            Pályaválasztásomkor nem volt kérdés számomra , hogy a késes és köszörűs szakmát választom. Azonban a szakmát formális iskolai képzés keretében nem tanulhattam, így általános mechanikai műszerészként végeztem 1998-ban.
            </p>

            <p>
            2016-ban nyitottam önálló műhelyt. Munkáim egyaránt alapulnak népművészeti hagyományokon, családi tradíciókon, és saját elképzeléseimen. Termékeim elkészítésekor ötvözni tudom a felmenőimtől tanult hagyományos eljárásokat a modern technológiákkal. Munkám során törekszem az esztétikára és a jó szakmai kivitelezésre.
            </p>

            <p>
            A XIII. Tűzzel-vassal fesztiválon zsűri különdíjat kaptam 2016-ban. 2018 -ban csatlakoztam a Zala Megyei Népművészeti Egyesülethez. Bicskáim bemutatásra kerültek a Zala Megyei Kamarák Házában, valamint időszaki kiállításon Sárváron a Nádasdy várban. Alkotásaimat rendszeresen zsűriztetem, 2018-ban Népi Tárgyalkotó Iparművész lettem.
            </p>

            <p>
            Bízom abban, hogy a honlapot böngészve Ön is megtalálja azt a terméket, ami felkelti érdeklődését, aminek mindennapjai során hasznát veszi!
            </p>
         </div>
    </div>

   </div>
    
  )
}

export default Introduction;
