import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Spinner } from 'reactstrap';
import { isItInStock, isItOnSale, getShortName, imgHost } from '../utils/utils';
import { Link } from 'react-router-dom';

function ProductScroller() {
   const [products, setProducts] = useState([]);
   const [loading, setIsLoading] = useState(false);
   const [error, setError] = useState("");

   useEffect(() => {
      fetchProducts();
   }, []);


   /* function addAnimation() {
      scrollers.forEach(scroller => {
         scroller.setAttribute("data-animated", true);

         const scrollerInner = scroller.querySelector(".scroller_inner");
         const scrollerContent = Array.from(scrollerInner.children);

         scrollerContent.forEach(item => {
            const duplicatedItem = item.cloneNode(true);
            duplicatedItem.setAttribute("aria-hidden", true);
            console.log(duplicatedItem);
            let p = document.createElement("p");
            scrollerInner.appendChild(p);
         });
      });
   } */

   async function fetchProducts() {
      setIsLoading(true);
      try {
         const url = `${process.env.REACT_APP_BACKEND_URL}/termekek/scroller/`;
         const {data} = await axios.get(url);
         if (Array.isArray(data) && data.length > 0) {
            //animáció miatt duplikáltan jelenítjük meg a termékeket
            let copyOfProducts = [...data];
            let duplicatedProducts = [...data, ...copyOfProducts];
            setProducts(duplicatedProducts);
         } else {
            setError("Nincs elérhető termék.");
         }
      } catch (err) {
         console.log(err);
         setError(`Hiba: ${err}`);
      } finally {
         setIsLoading(false);
      }
   }

   function renderProducts() {
      const prod = products.map((item, ind) =>
         <Link to={`/adatlap/${item.id_knives}`} className='scrollerLink' key={ind}>
            <li key={ind}>
               <img src={imgHost + item.thumbnail_path} alt={item.name} className='thumbnail' />
               
               <div className='p-info'>{isItInStock(item.stock)}
               {item.sale_percentage > 0 && <div className='onsale'>-{item.sale_percentage} %</div>}
               </div>
               <div className='p-name'>{getShortName(item.name)}</div>
               <div className='p-price'>{isItOnSale(item.sale_percentage, item.price)}</div>
            </li>
         </Link>
      );
      return <ul className='scroller_inner'>{prod}</ul>;
   }

   return (
      <div className='comp-wrapper'>
         <h1 className='welcome'>Üdvözöljük</h1>
         <div className='scroller'>
            {loading ? 
               (<Spinner>Loading...</Spinner>) 
               : 
               (products.length > 0 ? 
                  (renderProducts()) 
                  : 
                  (<p>{error}</p>)
               )
            }
         </div>
      </div>
      
   )
}

export default ProductScroller;
