import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Spinner } from 'reactstrap';
import { 
   isItInStock, 
   isItInStock_v2, 
   isItOnSale, 
   getShortName, 
   imgHost, 
   isItOnSale_v2} from '../utils/utils';
import { Link } from 'react-router-dom';
import Appeal from '../components/Appeal';

function InstockOnsale() {

   const [products, setProducts] = useState([]);
   const [loading, setIsLoading] = useState(false);
   const [error, setError] = useState("");
   const [noContent, setNoContent] = useState(false);

   useEffect(() => {
      fetchProducts();
   }, []);

   async function fetchProducts() {
      setIsLoading(true);
      try {
         const url = `${process.env.REACT_APP_BACKEND_URL}/termekek/instock-onsale/`;
         const {data} = await axios.get(url);
         if (Array.isArray(data) && data.length > 0) {
            setProducts(data);
            //console.log(data);
         } else {
            setError("Nincs elérhető termék.");
            setNoContent(true);
         }
      } catch (err) {
         //console.log(err);
         let errorMsg = err?.response?.data?.msg ?? "Jelenleg nincs akciós vagy készleten lévő termék.";
         setError(errorMsg);
         setNoContent(true);
      } finally {
         setIsLoading(false);
      }
   }

   function renderProducts() {
      const productCards = products.map((item, ind) =>
         <Link to={`/adatlap/${item.id_knives}`} className='scrollerLink' key={ind}>
            <div key={ind} className='mycard'>
               <img src={imgHost + item.thumbnail_path} alt={item.name} className='thumbnail' />

               <div className='p-info'>{isItInStock(item.stock)}
               {item.sale_percentage > 0 && <div className='onsale'>-{item.sale_percentage} %</div>}
               </div>
               <div className='p-name'>{getShortName(item.name)}</div>
               <div className='p-price'>
                  {isItOnSale_v2(item.sale_percentage, item.price, ind)}
               </div>
            </div>
         </Link>
      );
      return productCards;
   }

   return (
      <div className='comp-wrapper'>
         <div className='two-comp'>
         {!noContent && (
            loading ? 
               (<Spinner>Loading...</Spinner>) 
               : 
               (
               <div className='instock-wrapper'>
                  <h1>
                     Akciós vagy készleten lévő termékeink
                  </h1>
                  <div className='instock'>
                  {products.length > 0 ? 
                     (renderProducts())
                     :
                     (<div className='no-content'>{error}</div>)}
                  </div>
               </div>
               )
         )}
         <Appeal aloneInTheRow={noContent} />
         </div>
      </div>
   );
}

export default InstockOnsale;
