import React, {useState, useEffect} from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spinner } from 'reactstrap';
import { isItInStock, 
  isItOnSale, 
  getShortName, 
  imgHost } from "../utils/utils";

function SearchResults() {

  //const {state} = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const firstKeyword = queryParams.get('keyword');
  const [keyword, setKeyword] = useState(firstKeyword);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState("");
  const [filtered, setFiltered] = useState([]);

  useEffect(()=>{
    fetchData();
  }, [firstKeyword]);

  useEffect(()=>{
    if (products.length > 0) {
      filterData();
    }
  }, [products]);


  async function fetchData() {
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/termekek/all`;
      const { data } = await axios.get(url);
      if (Array.isArray(data) && data.length > 0) {
        //console.log(data);
        setProducts(data);
        //setFiltered(data);
      } else {
        setError("Sajnos hiba történt a termékek lekérése során. Kérjük próbálja meg újra!");
      }
    } catch (err) {
        let errorMsg = err.message ?? 
          "Ismeretlen hiba a termékek lekérése során.";
        setError(errorMsg);
    } finally {
      setIsLoading(false);
    }
  }

  function renderResults() {
    if (filtered.length === 0) {
      return <div className="nothing-find">Nincs találat</div>; 
    }
    let temp = filtered.map((item, ind) =>
      <Link 
        to={`/adatlap/${item.id_knives}`} 
        className='scrollerLink' 
        key={ind}
        state={keyword}
      >
        <div className="card-item" key={ind}>
          <div style={{ height: "135px"  }}>
            <img alt="Nincs kép" src={imgHost + item.thumbnail_path} />
          </div>
          <div className='p-info'>
            <span className={item.stock > 0 ? "in-stock" : ""}>
              {isItInStock(item.stock)}
            </span>
            {item.sale_percentage > 0 && 
              <div className='onsale'>
                -{item.sale_percentage} %
              </div>}
          </div>
          <div className='p-name'>{getShortName(item.name)}</div>
          <div className='p-price'>
            {isItOnSale(item.sale_percentage, item.price)}
          </div>
        </div>
      </Link>
    );
    return <div className="card-container">{temp}</div>;
  }

  function filterData() {
    if (keyword !== "") {
      let temp = products.filter(item => {
        return (
          item["name"].toLowerCase().includes(keyword.toLowerCase()) ? 
          true : false
        );
      });
      setFiltered(temp);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (keyword.length > 2) {
      setError("");
      filterData();
      queryParams.set('keyword', keyword);
      // Frissítjük a böngészési előzményeket a frissült URL-lel
      navigate(`?${queryParams.toString()}`);
    } else {
      setError("Kérlek adj meg legalább 3 karaktert a kereséshez!");
    }
  }

  return (
    <>
      <h2>Keresési eredmények</h2>
      <div className='finder-wrapper'> 
        <form onSubmit={e=>handleSubmit(e)}>
          <div className="form-group">
              <input 
                type="text" 
                name='keyword'
                placeholder='Termékkereső'
                value={keyword}
                onChange={e=>setKeyword(e.target.value)}
                className='main-search'
                required
              />
              <button className='search-btn'>Keresés</button>
          </div>
        </form>
      </div>
      <div className='search-results-wrapper'>
        {isLoading ?
          (<Spinner></Spinner>)
          :
          (error !== "" ?
            (<div className="nothing-find">{error}</div>)
            :
            (renderResults())
          )
        }
      </div>
    </>
  )
}

export default SearchResults;
