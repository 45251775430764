import React, {useContext, useEffect} from 'react';
import {BasketContext} from '../App';
import { checkBasketItems } from '../utils/utils';
import { Link } from 'react-router-dom';

function TopHeader() {

   const {itemsInBasket, setItemsInBasket} = useContext(BasketContext);

   useEffect(() => {
      setItemsInBasket(checkBasketItems());
   });

   function displayMobMenu() {
      const navContainer = document.querySelector(".nav-container");
      const primaryNav = document.querySelector(".primary-navigation");

      //const visibility = navContainer.getAttribute("data-visible");   
      navContainer.setAttribute("data-visible", true);
      primaryNav.setAttribute("aria-expanded", true);
   }

   function hideMobMenu() {
      const navContainer = document.querySelector(".nav-container");
      const primaryNav = document.querySelector(".primary-navigation");

      //const visibility = navContainer.getAttribute("data-visible");
      navContainer.setAttribute("data-visible", false);
      primaryNav.setAttribute("aria-expanded", false);
   }

  return (
    <div className='top-header'>
      <div className='mobile-brand-container'>
            <div className='mobile-brand'>Boznánszkykés webshop</div>
      </div>
      <header className='header-container'>
         <div className='mobil-menu' onClick={displayMobMenu}>
            <i 
               className="fa-solid fa-bars fa-2xl"
               style={{ color: "#cedc00" }}
            >
            </i>
         </div>
         <div>
            <a 
               href="https://www.facebook.com/groups/magyarbicska" target="_blank" 
               rel="noopener noreferrer">
               <i className="fa-brands fa-square-facebook fa-2xl"></i>
            </a>
         </div>
         <div className='nav-container' data-visible="false">
            <nav>
               <ul id="primary-navigation" className='primary-navigation'>
                  <li><Link to={"/"}>Főoldal</Link></li>
                  <li><Link to={"/bicskak/"}>Bicskák</Link></li>
                  <li><Link to={"/mives-bicskak/"}>Míves bicskák</Link></li>
                  <li><Link to={"/konyhakesek/"}>Konyhakések</Link></li>
                  <li><Link to={"/torok/"}>Tőrök</Link></li>
                  <li><Link to={"/galeria/"}>Galéria</Link></li>
               </ul>
            </nav>
            <button 
               className='mobile-nav-toggle'
               aria-controls='primary-navigation' 
               aria-expanded="false"
               onClick={hideMobMenu}>
                 Bezárás
            </button>
         </div>
         <div style={{ position: "relative"}}>
            <Link to={"/kosar/"}>
               <i 
                  className="fa-solid fa-cart-shopping fa-2xl" 
                  style={{color: "#cedc00" }}>
               </i>
            </Link>
            <div className='items-in-basket'>{itemsInBasket}</div>
         </div>
      </header>
    </div>
  )
}

export default TopHeader;
