import React, {useEffect, useState, useContext} from 'react';
import { 
   priceFormatter, 
   calcBasketSumPrice, 
   isItAllowedToInc } from '../utils/utils';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BasketContext } from '../App';

function Kosar() {

   const {setItemsInBasket} = useContext(BasketContext);
   const [basket, setBasket] = useState([]);
   const [orderData, setOrderData] = useState({
      comment: "",
      paymentMethod: "cashOnDelivery",
      deliveryCost: 0,
      default: true,
   });
   const [remainChar, setRemainChar] = useState(200);
   const [allowedForwardPaying, setAllowedForwardPaying] = useState(true);

   // 1, Kosár kiolvasása a sessionStorage-ból (basket)

   useEffect(() => {
      function getKosar() {
         const sessionBasket = sessionStorage.getItem("basket");
         if (sessionBasket && sessionBasket.length > 0) {
            let basketArray = JSON.parse(sessionBasket);
            setBasket(basketArray);
         }
      }
      getKosar();
   }, []);


   // 2, Megnézzük van-e már egyéb rögzített adat a rendeléssel kapcsolatban session storage-ben (orderData)

   useEffect(() => {
      function readOrderDataFromSessionStorage() {
         const SessionOrderData = sessionStorage.getItem("orderData");
         if (SessionOrderData && SessionOrderData.length > 0) {
            let orderDataObj = JSON.parse(SessionOrderData);
            
            let paymentMethod = "paymentMethod" in orderDataObj ? orderDataObj.paymentMethod : "";
            
            let comment = orderDataObj?.comment.length > 0 ? orderDataObj.comment : "";

            let deliveryCost = orderDataObj?.deliveryCost > 0 ? orderDataObj.deliveryCost : 0;

            let storedData = {
               comment,
               paymentMethod,
               deliveryCost,
               default: false,
            };
            //console.log(storedData);
            setOrderData(storedData);
         }
      }
      readOrderDataFromSessionStorage();
   }, []);


   //3, megvizsgálja, hogy a kosár tartalma alapján elérhető-e az előre utalásos fizetés (ha a kosár termékei készleten vannak)

   useEffect(() => {
      function checkPaymentMethodOptions() {
         //filterezzük a basketArray tömböt a következőek szerint: a 'productInStock' kulcsa 0-ás értéknél nagyobb (tehát van belőle készleten) és egyben nagyobb vagy egyenlő, mint a kosárban lévő mennyiség
         let filteredBasket = basket.filter(item => (item.productInStock > 0 && item.productInStock >= item.quantity));
         //console.log(filteredBasket);
         
         //ha a filterezett tömb hossza egyezik a kosár tömb hosszával, akkor minden elem teljesítette a követelményt => minden termékből van készleten, legalább a vásárolt mennyiségnek megfelelő db 
         if (filteredBasket.length === basket.length) {
            setAllowedForwardPaying(true); //ez esetben megengedetté válik az előre utalásos fizetés is
         } else {
            setAllowedForwardPaying(false);
         }
      }
      checkPaymentMethodOptions();
   }, [basket]);


   //Ha a kosár tartalma változik, akkor updatelni kell a sessionStorage-t

   useEffect(() => {
      if (basket.length > 0) {
         sessionStorage.setItem("basket", JSON.stringify(basket));
      } else {
         //ha a kosár üressé vált
         sessionStorage.removeItem("basket");
      }
   }, [basket]);


   //ha az orderData változik

   useEffect(() => {
      let remain = 200 - orderData.comment.length;
      setRemainChar(remain);
      if (!orderData.default) {
         sessionStorage.setItem("orderData", JSON.stringify(orderData));
      }
      //console.log(orderData);
   }, [orderData]);

   //ha megváltozik az "engedélyezettElőreUtalás" értéke hamisra (azaz, a kosárban lévő termékek közül valamelyikből nincs elegendő készlet), akkor a paymentMethod-ot "automatikusan" utánvétesre kell állítani 
   useEffect(() => {
      if (!allowedForwardPaying) {
         setOrderData(orderData => ({
            ...orderData, 
            paymentMethod: "cashOnDelivery",
            default: false
         }));
      }
   }, [allowedForwardPaying]);


   //lekérjük az aktuális kosárértékhez tartozó kiszállítási díjat

   useEffect(() => {
      async function getDeliveryCost(basketValue) {
         try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/deliverycost/`;
            const {data} = await axios.post(url, {
               paymentMethod: orderData.paymentMethod,
               orderValue: basketValue,
            });
            //console.log(data);
            setOrderData(prev => ({
               ...prev,
               deliveryCost: data.deliveryCost,
               default: false
            }));
         } catch (err) {
            //console.log(err);
            alert("Hiba. Nem sikerült a kiszállítási díj lekérése.");
         }
      }
      if (basket.length > 0 && orderData.paymentMethod !== "") {
         getDeliveryCost(calcBasketSumPrice(basket, false));
      }
   }, [basket, orderData.paymentMethod]);

   

   function renderBasketHeader() {
      const headings = 
         <tr>
            <th>Termék neve</th>
            <th>Egységár</th>
            <th>Akció mértéke</th>
            <th>Eladási ár</th>
            <th>Mennyiség</th>
            <th>Készleten</th>
            <th>Összesen</th>
            <th>Törlés</th>
         </tr>;
      return <thead>{headings}</thead>;
   }

   function deleteFromBasket(e) {
      const {id} = e.target.dataset;
      const newBasket = basket.filter(item => item.productId !== Number(id));
      //sessionStorage.setItem("basket", JSON.stringify(newBasket));
      /* if (newBasket.length === 0) {
         //azaz ha a kosár kiürült, akkor az OrderData-t töröljük sessionStorage-ból
         sessionStorage.removeItem("orderData");   
      }
      setBasketChangeIndicator(prev => { return !prev }); */
      setBasket(newBasket);
      setItemsInBasket(prev => prev - 1);
   }

   function calcItemPrice(price, quant) {
      let sum = price * quant;
      return priceFormatter(sum);
   }

   function inc(e) {
      const {id} = e.target.dataset;
      //alert(id);
      const newBasket = basket.map(item => {
         if (item.productId === Number(id)) {
            let newQuantity = item.quantity + 1;
            if (newQuantity < 11 && 
               isItAllowedToInc(item.productSale, item.productInStock, newQuantity)
            )
               return {...item, quantity: newQuantity};
            return item;
         }
         return item;
      });
      setBasket(newBasket);
      /* sessionStorage.setItem("basket", JSON.stringify(newBasket));
      setBasketChangeIndicator(prev => { return !prev }); */
   }

   function dec(e) {
      const {id} = e.target.dataset;
      //alert(id);
      const newBasket = basket.map(item => {
         if (item.productId === Number(id)) {
            let newQuantity = item.quantity - 1;
            if (newQuantity > 0)
               return {...item, quantity: newQuantity};
            return item;
         }
         return item;
      });
      setBasket(newBasket);
      /* sessionStorage.setItem("basket", JSON.stringify(newBasket));
      setBasketChangeIndicator(prev => { return !prev }); */
   }

   function renderBasketContent() {
      const rows = basket.map((item, ind) => (
         <tr key={ind}>
            <td data-cell="Termék név">{item.productName}</td>
            <td data-cell="Termék ár">{priceFormatter(item.productPrice)}</td>
            <td data-cell="Akció mértéke">{item.productSale} %</td>
            <td data-cell="Akciós ár">{priceFormatter(item.productDiscountedPrice)}</td>
            <td data-cell="Mennyiség">
               {item.quantity > 1 && (<button 
                  onClick={e => dec(e)} 
                  data-id={item.productId}
                  className='change-quantity'>
                  -
               </button>)}
               {item.quantity} db
               <button 
                  onClick={e => inc(e)} 
                  data-id={item.productId}
                  className='change-quantity'>
                  +
               </button>
            </td>
            <td data-cell="Készleten">{item.productInStock} db</td>
            <td data-cell="Tétel összesen">{calcItemPrice(item.productDiscountedPrice, item.quantity)}</td>
            <td data-cell="Művelet">
               <button 
                  className='deleteFromBasket' 
                  onClick={e => deleteFromBasket(e)}
                  data-id={item.productId}
               >
                  Eltávolít
               </button>
            </td>
         </tr>
      ));
      return <>{rows}</>;
   }

   function getTotal() {
      let total = calcBasketSumPrice(basket, false) + orderData.deliveryCost;
      return priceFormatter(total);
   }

   function renderBasketTotal() {
      let summary = 
         <>
            <tr>
               <td data-cell="hide" colSpan={6}>A kosár összértéke:</td>
               <td data-cell="Kosár összérték" colSpan={2}>{calcBasketSumPrice(basket)}</td>
            </tr>
            <tr>
               <td data-cell="hide" colSpan={6}>Kiszállítási díj:</td>
               <td data-cell="Kiszállítási díj" colSpan={2}>{priceFormatter(orderData.deliveryCost)}</td>
            </tr>
            <tr className='summary'>
               <td data-cell="hide" colSpan={6}>Összesen fizetendő:</td>
               <td data-cell="Összesen fizetendő" colSpan={2}>{getTotal()}</td>
            </tr>
         </>;
      return <>{summary}</>;
   }

   function handleChange(e) {
      setOrderData(actualOrderData => (
         {
         ...actualOrderData,
         comment: e.target.value,
         default: false
         }
      ));
   }

   function handlePaymentChange(e) {
      let selectedPayment = e.target.id;
      setOrderData(actualOrderData => (
         {
         ...actualOrderData, 
         paymentMethod: selectedPayment,
         default: false
         }
      ));
   }

   function renderPaymentSelector() {
      let selector = <div>
         <div>
            <input 
               type="radio" 
               name="paymentMethod" 
               id="cashOnDelivery"
               onChange={e => handlePaymentChange(e)}
               checked={orderData.paymentMethod === "cashOnDelivery"} 
               required            
            /> <label htmlFor="cashOnDelivery">Utánvét</label>
         </div>
         <div>
            <input 
               type="radio" 
               name="paymentMethod" 
               id="forwardPaying"
               onChange={e => handlePaymentChange(e)}
               checked={orderData.paymentMethod === "forwardPaying"}
               disabled={!allowedForwardPaying}
               required            
            /><label htmlFor="forwardPaying">Előre utalás (csak készleten lévő termékek esetén)</label>
         </div>
      </div>;
      return selector;
   }

   return (
      <>
      <h1>Kosaram</h1>
      <div className='mybasket-wrapper'>
         {basket.length > 0 ? 
            (<><div className='basket-table'>
                  <table className='mybasket'>
                     {renderBasketHeader()}
                     <tbody>
                        {renderBasketContent()}
                        {renderBasketTotal()}
                     </tbody>
                  </table>
               </div>
               <div className='order-comment'>
                  <label htmlFor="orderComment">Megjegyzés a rendeléshez:</label>
                  <div>
                     <textarea 
                        name="orderComment" 
                        id="orderComment" 
                        cols="60" 
                        rows="5"
                        maxLength={200}
                        onChange={e => handleChange(e)}
                        defaultValue={orderData.comment}
                        placeholder='Ide írhat megjegyzést'
                     >
                     </textarea>
                     <div>Hátralévő karatkerek száma: <span className='remainChar'>{remainChar}</span></div>
                  </div>
               </div>
               <div className='paying-method'>
                  {/* {allowedForwardPaying && (<p>Engedélyezett előre utalás</p>)} */}
                  <h2>Fizetési mód</h2>
                  {renderPaymentSelector()}
               </div>
               <div className='order-next-step'>
                  <Link to={"/rendelesi-adatok/"}>
                     Tovább az adatok megadására
                  </Link>
               </div>
            </>)
            :
            (<div className='basket-content'>Az Ön kosara jelenleg üres.</div>)
         }
      </div>
      </>
  )
}

export default Kosar;
