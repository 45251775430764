import React, {useState, useEffect} from 'react';
import { Spinner, Button } from 'reactstrap';
import { priceFormatter, calcBasketSumPrice, scrollTop } from '../utils/utils';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

function OrderSummary() {

   const [agreePayment, setAgreePayment] = useState(false);
   const [agreeAszf, setAgreeAszf] = useState(false);
   const [basket, setBasket] = useState([]);
   const [orderData, setOrderData] = useState({});
   const [customerData, setCustomerData] = useState({});
   const [error, setError] = useState("");
   const [isSending, setIsSending] = useState(false);
   const [completeOrder, setCompleteOrder] = useState({});
   
   const navigate = useNavigate();

   useEffect(() => {
      function readDataFromStorage() {
         const basketObj = sessionStorage.getItem("basket");
         if (basketObj && basketObj.length > 0) {
            //console.log(JSON.parse(basketObj));
            setBasket(JSON.parse(basketObj));
         } else {
            setError("Hiba a kosár adatainak olvasása során.");
            return;
         }
         
         const orderDataObj = sessionStorage.getItem("orderData");
         if (orderDataObj && orderDataObj.length > 0) {
            setOrderData(JSON.parse(orderDataObj));
         } else {
            setError("Hiba a kosár adatainak olvasása során.");
            return;
         }

         const customerDataObj = sessionStorage.getItem("customerData");
         if (customerDataObj && customerDataObj.length > 0) {
            setCustomerData(JSON.parse(customerDataObj));
         } else {
            setError("Hiba a vásárló adatainak olvasása során.");
            return;
         }
      }
      readDataFromStorage();
   }, []);

   useEffect(() => {
      function prepareDataToSend() {
         const dataToSend = {
            basket,
            customerData,
            orderData,
         };
         //console.log(dataToSend);
         setCompleteOrder(dataToSend);
      };
      if (error === "") {
         prepareDataToSend();
      }
      
   }, [basket, customerData, orderData]);

   async function postOrder() {
      scrollTop();
      setIsSending(true);
      try {
         const url = `${process.env.REACT_APP_BACKEND_URL}/rendeles/`;
         const {data} = await axios.post(url, completeOrder);
         //console.log(data);
         let response = {
            status: "ok",
            msg: data.msg,
         }
         sessionStorage.setItem("serverResponse", JSON.stringify(data));
      } catch (err) {
         //console.log(err);
         //alert("Hiba a rendelés végrehajtása során!");
         //console.log(err.response);
         let errorMsg = err?.response?.data?.error ?? err?.message ?? "Ismeretlen hiba";
         let error = {
            status: "error",
            msg: errorMsg,
         }
         sessionStorage.setItem("serverResponse", JSON.stringify(error));
      } finally {
         setTimeout(() => {
            setIsSending(false);
            navigate("/rendeles-visszaigazolas/");
         }, 1000);
      }
   }

   function handleChange(e) {
      let {id, checked} = e.target;
      if (id === "aszf") {
         setAgreeAszf(checked);
         return;
      }
      if (id === "payment") {
         setAgreePayment(checked);
      }
   }

   function calcItemPrice(price, quant) {
      let sum = price * quant;
      return priceFormatter(sum);
   }

   function getTotal() {
      let total = calcBasketSumPrice(basket, false) + orderData.deliveryCost;
      return priceFormatter(total);
   }

   function formatSale(salePerc) {
      if (salePerc === 0) {
         return "Nem akciós";
      }
      return `${salePerc} %`;
   }

   function renderBasketContent() {
      let headings = 
         <thead>
            <tr>
               <th>Termék</th>
               <th>Egységár</th>
               <th>Akció mértéke</th>
               <th>Eladási ár</th>
               <th>Mennyiség</th>
               <th>Tétel összesen</th>
            </tr>
         </thead>;
      let dataRows = basket.map((item, ind) => (
         <tr key={ind}>
            <td data-cell="Termék név">{item.productName}</td>
            <td data-cell="Termék ár">{priceFormatter(item.productPrice)}</td>
            <td data-cell="Akció mértéke">{formatSale(item.productSale)}</td>
            <td data-cell="Eladási ár">{priceFormatter(item.productDiscountedPrice)}</td>
            <td data-cell="Mennyiség">{item.quantity} db</td>
            <td data-cell="Tétel összesen">{calcItemPrice(item.productDiscountedPrice, item.quantity)}</td>
         </tr>
      ));
      let summaryRow = 
         <>
         <tr>
            <td data-cell="hide" colSpan={5}>Kosár összérték:</td>
            <td data-cell="Kosár összérték">{calcBasketSumPrice(basket)}</td>
         </tr>
         <tr>
            <td data-cell="hide" colSpan={5}>Kiszállítási díj:</td>
            <td data-cell="Kiszállítási díj">{priceFormatter(orderData.deliveryCost)}</td>
         </tr>
         <tr className='summary'>
            <td data-cell="hide" colSpan={5}>Összes fizetendő:</td>
            <td data-cell="Összesen fizetendő">{getTotal()}</td>
         </tr>
         </>;
      let tbody = 
         <tbody>
            {dataRows}
            {summaryRow}
         </tbody>;
      return <table className='mybasket'>{headings}{tbody}</table>;
   }

   function dataFormatter(field) {
      switch (field) {
         case "comment":
            return "Megjegyzés";
         case "paymentMethod":
            return "Fizetési mód";
         case "cashOnDelivery":
            return "utánvétes fizetés";
         case "forwardPaying":
            return "előre utalás";
         case "":
            return "nincs";
         case "post":
            return "postán maradó";
         case "home":
            return "otthon";
         case "post-point":
            return "postapont";
         default:
            return "";
         case true:
            return "igen";
         case false:
            return "nem";
      }
   }

   function renderOrderData() {
      let table = 
         <tbody>
            <tr>
               <td>Fizetési mód</td>
               <td>{dataFormatter(orderData.paymentMethod)}</td>
            </tr>
            <tr>
               <td>Megjegyzés a rendeléshez</td>
               <td style={{ maxWidth: "50ch", }}>{orderData.comment}</td>
            </tr>
         </tbody>;
      return <table className='summary-customer'>{table}</table>;
   }

   function renderCustomerData() {
      let table = 
         <tbody>
            <tr>
               <td>Név</td>
               <td>{customerData.name}</td>
            </tr>
            <tr>
               <td>Email</td>
               <td>{customerData.email}</td>
            </tr>
            <tr>
               <td>Telefonszám</td>
               <td>{customerData.phone}</td>
            </tr>
            <tr>
               <td>Kiszállítás típusa</td>
               <td>{dataFormatter(customerData.deliveryType)}</td>
            </tr>
            <tr>
               <td>Kiszállítási cím</td>
               <td>{customerData.pa_post_code}&nbsp;
                  {customerData.pa_settlement},&nbsp;
                  {customerData.pa_street}
               </td>
            </tr>
            {(customerData.deliveryType === "post" || customerData.deliveryType === "post-point") && (
               <tr>
                  <td>Posta száma</td>
                  <td>{customerData.post_number}</td>
               </tr>
               )}
            {customerData.diffBillAddress ? 
               (<>
                  <tr>
                     <td>Számlázási név</td>
                     <td>{customerData.bill_name}</td>
                  </tr>
                  <tr>
                     <td>Adószám (cég esetén)</td>
                     <td>
                        {customerData.tax_number !== "" ? 
                        customerData.tax_number : "-" }
                     </td>
                  </tr>
                  <tr>
                     <td>Számlázási cím</td>
                     <td>{customerData.ba_post_code}&nbsp;
                        {customerData.ba_settlement},&nbsp;
                        {customerData.ba_street}
                     </td>
                  </tr>
               </>)
               :
               (<tr>
                  <td>Számlázási cím</td>
                  <td>a postaival megegyező</td>
               </tr>)
            }
            <tr>
               <td>Hírlevél</td>
               <td>{dataFormatter(customerData.newsletter)}</td>
            </tr>
         </tbody>
      return <table className='summary-customer'>{table}</table>;         
   }

   function confirmOrder() {
      //alert("Sikeres rendelés!");
      postOrder();
   }

   if (isSending) {
      return (
         <div className='summary-wrapper'>
            <div className="spinner-container">
               <div>A rendelés küldése folyamatban...</div>
               <div>
                  <Spinner></Spinner>
               </div>
            </div>
         </div>
      );
   }

  return (
    <div>
      <h1>Rendelés összesítő</h1>
      {error !=="" ?
         (<div className='error-display'>
            {error}
         </div>)
         :
         (<div className='summary-wrapper'>
            <div className='summary-products'>
               <h2>Kosárban lévő termékek</h2>
               <div>
               {basket.length > 0 ? 
                  (renderBasketContent())
                  :
                  (<p>Úgy tűnik a kosár üres...</p>)
               }
               </div>
            </div>
            <div className='summary-payment'>
               <h2>Rendelés adatok</h2>
               <div>{renderOrderData()}</div>
               <div>
                  <Link to={"/kosar/"}>
                     <Button>Vissza a szerkesztéshez</Button>
                  </Link>
               </div>
            </div>
            <div className='summary-customer'>
               <h2>A megrendelő adatai</h2>
               <div>{renderCustomerData()}</div>
               <div>
                  <Link to={"/rendelesi-adatok/"}>
                     <Button>Vissza a szerkesztéshez</Button>
                  </Link>
               </div>
            </div>
            <div className='summary-checkout'>
               <h2>Rendelés elküldése</h2>
               <div>
                  <input 
                     type="checkbox" 
                     name="agree_aszf" 
                     id="aszf" 
                     checked={agreeAszf}
                     onChange={e => handleChange(e)}
                     />
                  <label htmlFor="aszf">A "Megrendelem" gombra való kattintással elfogadom az ÁSZF-et</label>
               </div>
               <div>
                  <input 
                     type="checkbox" 
                     name="agree_payment" 
                     id="payment" 
                     checked={agreePayment}
                     onChange={e => handleChange(e)}
                     />
                  <label htmlFor="payment">Tudomásul veszem, hogy a megrendelésem fizetési kötelezettséggel jár</label>
               </div>
               <div style={{ margin: "1rem auto", }}>
                  <Button 
                     onClick={confirmOrder}
                     color='warning'
                     disabled={!agreeAszf || !agreePayment}
                  >
                  Megrendelem
                  </Button>
               </div>
            </div>
         </div>)
      }
   </div>
  )
}

export default OrderSummary;
