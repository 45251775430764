import React from 'react';

function Elallasi() {
  return (
   <div className='consumer-law-infos'>
      <h1>Elállási nyilatkozat minta</h1>
      <div className='law-text-wrapper'>
         Ide fog beágyazódni a címben jelzett tartalom az oldal élesítését követően.
      </div>
   </div>
  )
}

export default Elallasi;
